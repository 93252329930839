import { render, staticRenderFns } from "./accordianBlock.vue?vue&type=template&id=47a351c6&scoped=true"
import script from "./accordianBlock.vue?vue&type=script&lang=js"
export * from "./accordianBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a351c6",
  null
  
)

export default component.exports