<template>
  <v-app class="pl-4 pr-4" tabindex="-1" id="inspire" v-if="siteSettingsLoaded">
    <div class="engage-header" tabindex="-1">
      <v-navigation-drawer
        app
        temporary
        :value="isHomeNavigationDrawerOpen"
        @input="TOGGLE_HOME_NAVIGATION_DRAWER(false)"
        width="300"
      >
        <div
          id="header"
          role="banner"
          class="pl-4 pr-4 primary-logo"
          :style="{background: $vuetify.theme.themes.light.primary}"
        >
          <v-img
            v-if="$store.state.wagtail.siteSettings.primary_logo == null"
            width="100%"
            height="96px"
            contain
            :src="$store.state.wagtail.siteSettings.site_logo"
          />
          <v-img
            v-else-if="$store.state.wagtail.siteSettings.primary_logo"
            width="100%"
            height="96px"
            contain
            :src="$store.state.wagtail.siteSettings.primary_logo"
          />
        </div>
        <div id="nav" role="navigation" aria-label="Primary" class="app-menu">
          <FocusLoop :is-visible="activeTrapLB">
            <v-list nav dense ref="navigationMenuList">
              <v-list-item
                :data-testid="'navigation-drawer-item'+i"
                v-for="(menuItem, i) in siteStructure"
                :key="i"
                :to="menuItem.pathname"
                link
                :style="navStyle(menuItem)"
                color="secondary"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ menuItem.menu_icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent="logOut" ref="logoutTest" data-testid="navigation-drawer-logout">
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </FocusLoop>
        </div>
        <div
          class="pl-4 pr-4 secondary-logo"
          :style="{background: $vuetify.theme.themes.light.primary}"
        >
          <v-img 
            v-if="$store.state.wagtail.siteSettings.secondary_logo !== null" 
            width="100%" height="100%" contain 
            :src="$store.state.wagtail.siteSettings.secondary_logo" 
          />
        </div>
      </v-navigation-drawer>
    </div>

    <v-main
      id="content"
      role="main"
      tabindex="-1"
      :class="showHeader ? '' : 'pt-0'"
    >
      <router-view> </router-view>
    </v-main>

    <!-- <v-footer color="primary" app>
      <span class="white--text"
        >&copy; {{ new Date().getFullYear() }} - Powered by GHD Engage</span
      >
    </v-footer> -->
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { FocusLoop } from "@vue-a11y/focus-loop";
import * as arrowKeyNavigation from "arrow-key-navigation";
//enables next & previous tab style DOM navigation using arrow keys
arrowKeyNavigation.register();

export default {
  props: {
    source: String,
  },

  data: () => ({
    drawer: false,
    noHeader: true,
    customFont: null,
    activeTrapLB: false,
    loaded: false,
    siteSettingsLoaded: false,
  }),
  components: { FocusLoop },

  beforeCreate() {
    if (!localStorage.getItem("accessibilityModeState")) {
      localStorage.setItem("accessibilityModeState", false);
    }
  },

  mounted() {

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        //if esc key was not pressed in combination with ctrl or alt or shift
        this.TOGGLE_DRAWER(false);
      }
    });

    this.$store.dispatch("wagtail/getSiteSettings");
    this.$store.dispatch("wagtail/getSiteStructure");
    
  },

  methods: {
    ...mapActions("toolbar", {
      TOGGLE_DRAWER: "TOGGLE_HOME_NAVIGATION_DRAWER",
    }),

    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },

    accessibilityModeActive() {
      console.log("activated");
      if (this.accessibilityMode == true) {
        this.accessibilityFlag = false;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      } else {
        this.accessibilityFlag = true;
        this.$store.commit(
          "wagtail/setAccessibiltyMode",
          this.accessibilityFlag,
          { root: true }
        );
        console.log(this.accessibilityFlag, "access flag");
      }
    },
    navStyle(menuItem) {
      let style = "";
      style += "padding-left:" + (16 + 12 * menuItem.depth) + "px";
      return style;
    },
    TOGGLE_HOME_NAVIGATION_DRAWER() {
      this.drawer = !this.drawer;
      console.log(this.drawer);
      if (this.drawer) {
        console.log("SHOW");
        this.activeTrapLB = true;
      } else {
        console.log("HIDE");
        this.activeTrapLB = false;
        this.TOGGLE_DRAWER(false);
      }
    },
    updateThemeFromApiResponse(response) {
      
      if (response.primary_colour !== null) {
        this.$vuetify.theme.themes.light.primary = response.primary_colour
      }

      if (response.secondary_colour !== null) {
        this.$vuetify.theme.themes.light.secondary = response.secondary_colour
      }

      if (response.favicon !== null) {
        const favicon = document.getElementById("favicon");
        favicon.href = response.favicon;
      }
      
      if (response.min_hotspot_size !== null) {
        this.$store.dispatch(
          "wagtail/updateMinHotspotSize",
          response.min_hotspot_size
        );
      }
      if (response.max_hotspot_size !== null) {
        this.$store.dispatch(
          "wagtail/updateMaxHotspotSize",
          response.max_hotspot_size
        );
      }

      this.customFont = response.custom_font;

      if (this.customFont !== null) {

        import("../public/css/variables.scss")
          .then(() => {
            console.log(
              "[SiteSettings] variables.scss file imported successfully"
            );
          })
          .catch((error) => {
            console.error(
              "[SiteSettings] Error importing variables.scss file:",
              error
            );
          });
      }
      this.customCss = response.custom_css;

      if (this.customCss !== null) {
        //appends a new style sheet uploaded from wagtail to the vue app component
        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = this.customCss;
        document.head.appendChild(style);
      }
    },
  },
  computed: {
    siteSettings() {
      return this.$store.state.wagtail.siteSettings
    },

    ...mapState("toolbar", ["isHomeNavigationDrawerOpen"]),
    currentUser() {
      return this.$store.state.auth.user;
    },
    //add a new computed property for nav drawer logo
    siteStructure() {
      let siteStructure = [];
      for (const item of this.$store.state.wagtail.siteStructure) {
        //TODO: Unsure how reliable this is.  Looks at the URL to calculate depth by counting slashes in the URL
        item.depth = item.meta.html_url.split("/").length - 4;
        let url = new URL(item.meta.html_url);
        item.pathname = url.pathname;
        item.menutitle = Array(item.depth + 1).join(" -") + " " + item.title;
        siteStructure.push(item);
      }

      return siteStructure;
    },
    appBarClass() {
      if (!this.showHeader) return "transparent-header";
      return "normal-header";
    },
    showHeader() {
      return this.$store.state.wagtail.showHeader;
    },
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    pageTitle() {
      return this.$store.state.wagtail.currentPage.title;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
  },
  watch: {
    
    siteSettings(newSettings) {
      if(Object.keys(newSettings).length > 0) {
        this.updateThemeFromApiResponse(newSettings);
        this.siteSettingsLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-by-side {
  display: inline-flex;
}

.header {
  height: 96px;
}

.menu-hamburg {
  display: none;
}

@media (max-width: 600px) {
  .logo-hidden {
    display: none;
  }
}

@media (max-width: 1400px) {
  .menu-hidden {
    display: none;
  }

  .menu-hamburg {
    display: block;
  }
}

.transparent-header {
  pointer-events: none;
  margin: 0px;
  padding: 0px;
}

.normal-header {
}

.pass-thru {
  pointer-events: none;
}

.clickable {
  pointer-events: all;
}

.logo-background {
  background-color: #1c3c52;
}

.use-mouse {
  pointer-events: all;
}

.engage-header ::v-deep .v-toolbar__content {
  padding: 0px !important;
}

.header-container {
  height: 100%;
}

.header-row {
  height: 100%;
}

.site-logo {
  max-height: 100%;
}

.app-menu {
  position: absolute;
  top: 96px;
  bottom: 96px;
  width: 100%;
  overflow-y: auto;
}

.primary-logo {
  position: absolute;
  top: 0px;
  height: 96px;
  width: 100%;
}

.secondary-logo {
  position: absolute;
  height: 96px;
  bottom: 0px;
  width: 100%;
}

.header-page-title {
  text-shadow: 1px 1px 2px #222;
}

.img-shadow {
  -webkit-filter: drop-shadow(1px 1px 1px #222);
  filter: drop-shadow(1px 1px 1px #222);
}

::v-deep .v-dialog__content {
  /* align-self: flex-start; */
  height: 100vh !important;
}
</style>
