<template>
  <v-fade-transition>
    <div
      tabindex="-1"
      class="lightbox"
      transition="fade-transition"
      v-show="showLightbox"
    >
      <FocusLoop :is-visible="activeTrapLB">
        <v-toolbar dark flat color="transparent">
          <v-scale-transition>
            <v-btn
              v-if="hasBack && dataFromQLB"
              class="mr-4 lightbox-btn"
              rounded
              dark
              color="secondary"
              @click="$emit('emit-back')"
            >
              <v-icon>mdi-keyboard-backspace</v-icon> Back
            </v-btn>
          </v-scale-transition>
          <v-spacer></v-spacer>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-switch
            v-if="accessibilityMode && isVideo && hasAccessibleVideoAvailable"
            inset
            class="lightbox-btn accessibility-switch"
            style="padding-top: 1.5rem; padding-right: 1rem"
            @click="$emit('accessibilityvideo')"
            label="Toggle Audio"
            data-testid="accessibility-switch-audio"
          ></v-switch>
          <v-switch
            v-if="accessibilityMode && hasBeforeAfter"
            v-model="accessibilityBeforeAfterFlag"
            inset
            class="lightbox-btn accessibility-switch"
            style="padding-top: 1.5rem; padding-right: 1rem"
            @click="$emit('accessibilitybeforeafter')"
            label="Toggle Before/After"
            data-testid="accessibility-switch-beforeafter"
          ></v-switch>

          <v-switch
            v-if="accessibilityMode && accessibleTextAvailable"
            v-model="accessibilityTextFlag"
            inset
            class="lightbox-btn accessibility-switch"
            style="padding-top: 1.5rem; padding-right: 1rem"
            @click="$emit('accessibility')"
            label="Toggle Text"
            data-testid="accessibility-switch-text"
          ></v-switch>

          <v-scale-transition origin="center center">
            <v-btn
              data-testid="lightbox-download-btn"
              v-if="canDownload"
              class="mr-4 lightbox-btn"
              rounded
              dark
              color="secondary"
              @click="$emit('download')"
            >
              <v-icon>mdi-download</v-icon> Download
            </v-btn>
          </v-scale-transition>

          <v-switch
            v-if="experienceJourney && accessibilityMode"
            label="Toggle Accessibility"
            color="secondary"
            class="mt-5 mr-2"
            v-model="videoToggle"
          >
          </v-switch>

          <v-btn
            v-if="!roomGuideData.enabled"
            rounded
            darkemit
            class="lightbox-btn"
            color="secondary"
            :id="currentLightboxButtonID"
            data-testid="lightbox-close-btn"
            @click="$emit('closed')"
          >
            <v-icon>mdi-close</v-icon> Close
          </v-btn>
          <v-btn
            v-if="roomGuideData.enabled"
            rounded
            darkemit
            class="lightbox-btn glowing-effect"
            color="secondary"
            :id="currentLightboxButtonID"
            data-testid="lightbox-next-hotspot-btn"
            @click="nextGuide"
          >
            Next <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="card">
          <slot class="video"></slot>
        </div>
      </FocusLoop>
    </div>
  </v-fade-transition>
</template>

<script>
import { FocusLoop } from "@vue-a11y/focus-loop";

export default {
  name: "LightboxBase",
  props: [
    "title",
    "showDownload",
    "content",
    "accessibilityTextFlag",
    "accessibilityMode",
    "showAccessibleVideo",
    "accessibilityBeforeAfterFlag",
    "showAccessibleText",
    "showLightbox",
    "hasAccessibleText",
    "showBack",
    "dataFromQLB",
    "hasAccessibleVideo",
    "isBeforeAfter",
    "streamfieldShowing",
  ],
  data: () => ({
    dialog: true,
    currentFocusIndex: 0,
    activeTrapLB: true,
    computedValueFromChild: null,
    videoToggle: false,   
  }),
  components: { FocusLoop },
  beforeDestroy() {
    //Remove the lightbox from the store if it no longer exists
    this.deregisterLightbox();
    this.$root.$emit("show-previous-lightbox-buttons");
  },
  created() {
    this.$on("answer-val-changed", this.handleComputedPropertyChange);
  },
  mounted() {    
    console.log("[STREAM]", this.streamfieldShowing);
    console.log(
      this.accessibilityTextFlag,
      this.accessibilityMode,
      this.textAvailable,
      this.hasAccessibleVideo,
      "MOUNTING hello console"
    );
    let self = this;

    this.$root.$on("hide-previous-lightbox-buttons", () => {
      const lightboxes = this.$store.state.lightbox.lightboxObjects;
      if (lightboxes.length > 1) {
        const lastLightboxId = lightboxes[lightboxes.length - 1];
        if (this._uid !== lastLightboxId) {
          this.$emit("hide-lightbox-buttons");
        }
      }
    });
    this.$root.$on("show-previous-lightbox-buttons", () => {
      const lightboxes = this.$store.state.lightbox.lightboxObjects;
      const lastLightboxId = lightboxes[lightboxes.length - 1];
      if (this._uid === lastLightboxId) {
        this.$emit("show-lightbox-buttons");
      }
    });

    this.updateLightboxStatus();

    window.addEventListener("keydown", function (event) {
      let isTabPressed = event.key === "Tab";
      if (!isTabPressed) {
        if (event.key === "Escape") {
          self.$emit("closed");
        }
      }
    });
  },
  methods: {
    nextGuide() {
      this.$emit("closed");
    
      this.$store.dispatch("wagtail/nextStepGuide");
    },
    updateLightboxStatus() {
      if (this.showLightbox) {
        this.registerLightbox();
        this.$root.$emit("hide-previous-lightbox-buttons");
      } else {
        // Not sure if this is ever reached
        // Write code in beforeDestroy() to handle stuff when de-registering the lightbox
        this.deregisterLightbox();
      }
    },
    registerLightbox() {
      this.$store.dispatch("lightbox/pushLightbox", this._uid);
    },
    deregisterLightbox() {
      this.$store.dispatch("lightbox/popLightbox", this._uid);
    },
    handleComputedPropertyChange(newValue) {
      console.log(newValue, "@@@@Base");
      this.computedValueFromChild = newValue;
    },
  },
  computed: {
    roomGuideData() {
      return this.$store.state.wagtail.roomGuide;
    },
    experienceJourney() {
      return this.$store.state.map.experienceJourney;
    },
    videoToggleStore() {
      return this.$store.state.map.videoToggle;
    },

    canDownload() {
      return this.showDownload;
    },
    hasBack() {
      return this.showBack;
    },
    isVideo() {
      return this.showAccessibleVideo;
    },
    accessibleTextAvailable() {
      return this.hasAccessibleText;
    },
    hasAccessibleVideoAvailable() {
      return this.hasAccessibleVideo;
    },
    hasBeforeAfter() {
      return this.isBeforeAfter;
    },
    isTopLightbox() {
      if (this.$store.state.lightbox.lightboxObjects.length <= 0) return false;

      //Return true if the last item in the lightbox list is this lightbox
      return (
        this.$store.state.lightbox.lightboxObjects[
          this.$store.state.lightbox.lightboxObjects.length - 1
        ] == this._uid
      );
    },
    currentLightboxButtonID() {
      return "lightboxClosebtn" + this._uid;
    },
  },
  watch: {
    roomGuideData: {
      deep: true,
      handler(newVal, oldVal) {        
      },
    },
    videoToggleStore() {
      this.videoToggle = this.videoToggleStore;
    },
    videoToggle() {
      this.$store.commit("map/setVideoToggle", this.videoToggle);
    },
    showLightbox() {
      this.updateLightboxStatus();
    },
    isTopLightbox() {
      if (this.isTopLightbox && this.streamfieldShowing == false) {
        console.log(this._uid + " - I'm now the top level lightbox");
        let self = this;
        self.$nextTick(() => {
          //focus the first button in the lighbox to engage focus shift and focus trap
          let targetElement = document.getElementById(
            self.currentLightboxButtonID
          );
          targetElement.focus();
        });
      } else {
        console.log(this._uid + " - I'm no longer the top level lightbox");
      }
    },
    streamfieldShowing() {
      if (this.isTopLightbox && this.streamfieldShowing == false) {
        console.log(this._uid + " - I'm now the top level lightbox");
        let self = this;
        self.$nextTick(() => {
          //focus the first button in the lighbox to engage focus shift and focus trap
          let targetElement = document.getElementById(
            self.currentLightboxButtonID
          );
          targetElement.focus();
        });
      } else {
        console.log(this._uid + " - I'm no longer the top level lightbox");
      }
    },
  },
};
</script>

<style scoped>
.lightbox {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  backdrop-filter: blur(10px);
}

.card {
  position: absolute;
  top: 64px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.caption {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.75);
  color: white;
}
.accessibility_fab {
  bottom: 20px;
}
.lightbox-btn:focus-visible {
  outline: 4px dashed darkorange;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px var(--v-secondary-base), 0 0 10px var(--v-secondary-base), 0 0 15px var(--v-secondary-base);
  }
  50% {
    box-shadow: 0 0 15px var(--v-secondary-base), 0 0 30px var(--v-secondary-base), 0 0 45px var(--v-secondary-base);
  }
  100% {
    box-shadow: 0 0 5px var(--v-secondary-base), 0 0 10px var(--v-secondary-base), 0 0 15px var(--v-secondary-base);
  }
}

.glowing-effect {
  animation: glowing 1.5s infinite;
}

@media (max-width: 600px) {
  .card {
    position: absolute;
    top: 58px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
.accessibility-switch {
  padding-top: 1.5rem;
  padding-right: 1rem;
}
</style>
