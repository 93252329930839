<template>
  <LightboxContentLayout
    :accessibilityText="data.video.accessibility_text"
    :ariaDescription="ariaDescription"
    :captionText="data.video.caption"
    :accessibilityTextFlag="accessibilityTextFlag"
  >

    <View360
    v-if="!accessibilityVideoFlag"
      ref="viewer"
      @render="checkVideoHasEnded($event)"
      @load="contentLoaded()"
      @ready="testFunc()"
      class="view360-container"
      :projection="projection"
      :initialYaw="data.heading"
      :autoplay="{'delay':5000,'delayOnMouseLeave':1000,'speed':-2,'pauseOnHover':true}"
      style="width: 100%; height: 100%"
    />
    <video
    v-else
      :aria-label="accessibilityLabel"
      class="lightbox-video"
      controls
      ref="videoRef"
      type="video/mp4"
      crossorigin="anonymous"
    >
      <source :src="videoFile" type="video/mp4" />
    </video>
  </LightboxContentLayout>
</template>

<script>
import LightboxContentLayout from "./LightboxContentLayout.vue";
import { View360, EquirectProjection, ControlBar  } from "@egjs/vue-view360";
import "@egjs/vue-view360/css/view360.min.css";

export default {
  name: "LightboxVideo360Card",
  data: () => ({
    dialog: true,
    isMobile: false,
    windowWidth: window.screen.width,
    isTablet: false,
    isLaptop: false,
    projection: null,
    videoInstance:null,
    viewerInstance: null
  }),
  props: [
    "data",
    "accessibilityTextFlag",
    "accessibilityVideoFlag",
    "accessibilityMode",
    "accessibility",
    "ariaDescription",
    "currentContent"
  ],
  components: { LightboxContentLayout, View360 },
  mounted() {
    console.log(this.data, "video light data content");
    console.log("CCA MOUNTED video360");
  const videoElement = this.$refs.hiddenVideo;

    const video = this.$refs.videoRef;
    const source = video.querySelector("source");

    console.log(
      this.currentContent,
      " CCA current vid content and current sources:",
      source.src, // source element's src
      video.src // video's src (might be empty initially)
    );

    video.pause();

    video.addEventListener("loadeddata", () => {
      console.log(
        "CCA loadeddata event fired",
        "video src:",
        video.src,
        "source src:",
        source.src
      );

    if (video.readyState >= 3) {
        // Now check if the val matches either the video or source src
        if (
          (this.data.autoplay && this.currentContent === video.src) ||
          (this.data.autoplay && this.currentContent === source.src)
        ) {
          console.log(
            this.currentContent,
            "CCA current vid content and current source MATCH PLAY"
          );
          video.play();
        } else {
          console.log("CCA Video and source do not match the current content");
        }
      }
    });

    video.load();
     this.pause360Video = function () {
    videoElement.pause();  // Pauses the actual video element
  };

    console.log(this.data, "video360 light data content");
  },
  created() {
    if(this.data.video){
      if(this.data.video.video !== undefined){
        let src = "";
        console.log('i am active1')
          // if (process.env.NODE_ENV === "development") {
          //   src =
          //     process.env.VUE_APP_BACKEND_URL.slice(0, -1) +
          //     this.data.video.video.file;
          // } else{
            src = this.data.video.video.file;
          // }
        console.log('src', src)
        this.projection = new EquirectProjection({
            src: src,
            video:true
          });
      }else if(this.data.video !== undefined){
        let src = "";
        //  if (process.env.NODE_ENV === "development") {
        //     src =
        //       process.env.VUE_APP_BACKEND_URL.slice(0, -1) +
        //       this.data.video.file;
        //   } else{
                console.log('i am active2')

        src = this.data.video.file;
          // }
        this.projection = new EquirectProjection({
        src: src,
       video:true
      });
      }
    }

  },
  computed: {
    videoToggle() {
      return this.$store.state.map.videoToggle
    },
    video() {
      if (this.data && this.data.video.file) return this.data.video.file;
      return undefined;
    },
    videoFile(){
      if(this.accessibilityVideoFlag){
        return this.data.video.accessible_video
      }else  return this.data.video.file
    }, 
    videoAccessibilityFile () {
      return this.data.videoAccessibility &&
        this.data.videoAccessibility.video &&
        this.data.videoAccessibility.video.file
    },
    videoAccessibility() {
      if (this.videoToggle) {
        if (this.videoAccessibilityFile) {
          if (process.env.NODE_ENV === "development") {
            return (
              process.env.VUE_APP_BACKEND_URL.slice(0, -1) +
              this.videoAccessibilityFile
            );
          }
  
          return this.videoAccessibilityFile
        }
      }
      return this.data.video && this.data.video.video.file;
    },
    // videoAccessibility() {
    //   if (this.accessibilityMode && this.accessibilityVideoFlag)
    //     return this.data.video.accessible_video;
    //   return this.data.video.file;
    // },
    accessibilityLabel() {
      if (
        this.data.videoAccessibility &&
        this.data.videoAccessibility.video.file
      )
        return this.data.videoAccessibility.video.accessibility_text;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.video.caption)
        if (this.data.video.caption !== undefined) return true;
      return false;
    },
    // accessibilityMode() {
    //   return this.$store.state.wagtail.accessibilityMode;
    // },
    hasAccessibleText() {
      if (this.data && this.data.video.accessibility_text) return true;
      return false;
    },
    // videoWidth() {
    //   if (this.accessibilityTextFlag) return 60 + '%'
    //   if (this.hasAccessibleText &&
    //     this.hasCaption &&
    //     this.accessibilityTextFlag == true) return 70 + "%";
    //   return 100 + '%'
    // }
  },
  watch: {
    accessibilityVideoFlag(newValue) {
      this.pauseVideo()
      this.$nextTick(() => {
      const video = this.$refs.videoRef; // Reference to the video element
      const source = video.querySelector("source");
          
      if (newValue === true) {
        video.src = this.data.video.accessible_video;
      }else if (newValue === false){
        video.src = this.data.video.file;
      } else {
      console.log("Accessibility Video Flag is neither true nor false.");
    }

     video.load();
    video.pause();
  
  
            // if (video.readyState >= 3) {
        // Now check if the val matches either the video or source src
        if (
          (this.data.video.autoplay && this.currentContent === video.src) ||
          (this.data.video.autoplay && this.currentContent === source.src)
        ) {
          console.log(
            this.currentContent,
            "CCA current vid content and current source MATCH PLAY 360"
          );
          video.play();
        } else {
          console.log("CCA Video and source do not match the current content 360");
        }
      // }
      })


    },
    currentContent(newVal) {
                          this.pauseVideo()

      console.log("newval for video360",newVal, this.$refs.videoRef.querySelector("source").src)
      const video = this.$refs.videoRef;
      const source = video.querySelector("source");


      // Log the initial state of the video and source src

      console.log(
        newVal,
        " CCA WATCHER current vid content and current sources: video360",
        source.src, // source element's src
         video.src // video's src (might be empty initially)
      );

      // Force a reload to trigger the 'loadeddata' event
      if ( newVal != video.src && newVal != source.src || newVal != this.$refs.videoRef.querySelector("source").src) {
        // Pause the video initially
        console.log("cca watcher pausing vid 360")
        video.pause();
        video.load();
      } else {
        if (this.data.video.autoplay) {
          video.play();
        }
      }

    }
  },
  methods: {
    textFlag() {
      console.log("triggered here from image");
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
    pauseVideo() {
       this.$nextTick(() => {

      const playControl = document.querySelector(".view360-controls-play");
      const pauseControl = document.querySelector(".view360-controls-pause");

      console.log("play and pause", playControl, pauseControl)
      if (playControl  == null ){
        console.log("Play button is visible. Clicking pause.");
        pauseControl.click();
      } 
      // If pause button is visible, click the play button
      else if (pauseControl  == null ) {
        console.log("Pause button is visible. Clicking play.");
        playControl.click();
      }
       })
    },
    testFunc() {
      console.log("fire command");
      const videoElement = this.projection.video;  // Access the video element

      this.$refs.viewer.addPlugins(new ControlBar());
      console.log("viewer instance:", this.projection)
        if (this.projection && this.projection.pause) {
    this.projection.pause(); // Attempt to pause the video through the projection instance
  } else {
    console.log("No pause method available on projection instance");
  }

    },
    checkVideoHasEnded() {
      const time = document.getElementsByClassName("view360-controls-time")[0];
      if (!time) return;

      const [currentTime, videoDuration] = time.innerHTML.split(" / ");

      if (currentTime === videoDuration) {
        this.$emit("ended");
      }
    },
    contentLoaded() {
      this.$emit("loaded");
    },
  },
};
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

video {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.view360-canvas {
  width: 1000px;
  height: 1000px;
}

.full-screen {
  width: 100%;
  height: 100%;
}

.lightbox-video {
  width: 100%;
  height: 100%;
}
</style>
