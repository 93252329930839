
// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb#:~:text=Converting%20hex%20to%20RGB
export const hexToRgb = (hex) => {
  return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
             ,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))
}

//https://github.com/scottcorgan/contrast/blob/master/index.js
export const isDark = (hex) => {
  const rgb = hexToRgb(hex)
  var color = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) /1000);
  return (color <= 180) ? true : false;
}