<template>
<div >
  <v-card :style="indexPriority"  :class="cardPosition" fixed  @click="clickSticky()" data-testid="map-sticky-card">
    <!-- Your card content goes here -->
    <v-img :src="stickyItemData.thumbnail" cover height="200px"></v-img>
    <v-card-title>{{ stickyItemData.card_header }}</v-card-title>
    <v-card-text>
      {{ stickyItemData.card_content }}
    </v-card-text>
  </v-card>
   <LightboxGallery
      :showLightbox="lightboxContent.length > 0 && lightboxReady"
      :content="lightboxContent"
      @closed="closeLightbox()"
    />
</div>
</template>

<script>
import LightboxGallery from "../lightbox/LightboxGallery.vue";

export default {
  name: "MapStickyCard",
  props: { stickyItemData: Object },
  data: () => ({
    stickyPosition: undefined,
    lightboxReady: false
  }),
  components: {LightboxGallery},
  mounted() {
    console.log(this.stickyItemData, "page data from mapsticky card vue");
  },
  methods: {
  clickSticky(){
    if(this.lightboxContent[0].type == "external_link" ){
          this.lightboxReady = false;
          window.location.href = this.lightboxContent[0].value.url
    }else if(this.lightboxContent[0].type == "internal_page_link"){
      this.lightboxReady = false;
      const dirtyUrl = this.lightboxContent[0].value.page.meta.html_url
      //NOTE: Only Enable when testing on Local
      // const cleanUrl = dirtyUrl.replace("8000", "8080")
      
      const cleanUrl = dirtyUrl.replace("-api", "")
      window.location.href = cleanUrl

    }else this.lightboxReady = true;
  },
  closeLightbox() {
      //TODO: Make map popups update routes
      // this.$router.push(new URL(this.pageData.meta.html_url).pathname);
      this.$store.commit("wagtail/getPopupSuccess", undefined);
      this.lightboxReady = false;
      //Return focus to previous element
      //this.$refs.sidebar.$children[this.counter_flag].$el.focus();
    },
  },
  computed: {
    cardPosition() {
      if (this.stickyItemData.sticky_position === "top-left") {
        return 'sticky-card-top-left'
      } else if (this.stickyItemData.sticky_position === "top-right") {
        return 'sticky-card-top-right'

      } else if (this.stickyItemData.sticky_position === "bottom-left") {
        return 'sticky-card-bottom-left'

      } else if (this.stickyItemData.sticky_position === "bottom-right") {
        return 'sticky-card-bottom-right'
      }else 
      return 'sticky-card-bottom-right'

    },
    lightboxContent(){
      console.log(this.stickyItemData.content, "returned lightboxContent from sticky")
      return this.stickyItemData.content
    },
    indexPriority(){
       if(this.lightboxReady == true){
        return 'z-index:0'
       } else return 'z-index:1'
    },
  },
};
</script>

<style scoped>
.sticky-card-bottom-right {
  position: fixed;
  bottom: 2rem;
  right: 12px;
  width: 300px;
}
.sticky-card-bottom-left {
  position: fixed;
  bottom: 4.2rem;
  left: 515px;
  width: 300px;
}

.sticky-card-top-right {
  position: fixed;
  top: 5rem;
  right: 56px;
  width: 300px;
}


.sticky-card-top-left {
  position: fixed;
  top: 8rem;
  left: 517px;
  width: 300px;
}

</style>