<template>
  <div>
    <v-card>
      <v-card-title class="text-subtitle-1" v-if="groupData.title !== ''">
        {{ groupData.title }}
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2" v-if="groupData.subtitle !== ''">
        {{ groupData.subtitle }}
      </v-card-subtitle>
      <v-radio-group data-testid="radiobtn-group"
        v-if="groupData.group_item_type == 'radioBtn'"
        v-model="groupValue"
        color="primary"
        mandatory
        class="mt-0"
      >
        <v-list-item
          v-for="(item, i) in groupData.layers"
          :key="i"
          color="primary"
          :data-testid="'radiobtn-item'+i"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-radio :name="'radioBtn-' + i" :label="item.nice_name" :value="i" :data-testid="'radiobtn-input'+i"> </v-radio>
            </v-list-item-title>

            <StreamfieldBlock
              v-for="(block, blockIndex) in item.additional_detail"
              v-show="groupValue == i"
              :id="i"
              :key="blockIndex"
              :block="block"
            ></StreamfieldBlock>
            <!-- <v-list-item-subtitle v-text="item.nice_name" class="ml-8"></v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-radio-group>

      <v-list v-if="groupData.group_item_type == 'checkBox'" data-testid="checkbox-group">
        <v-list-item-group
          multiple
          v-model="checkboxGroupValue"
          class="mt-0"
          color="primary"
          @change="updateStoreCheckbox"
        >
          <v-list-item
            v-for="(item, i) in groupData.layers"
            :value="item.layer_name"
            :key="i"
            :data-testid="'checkbox-item'+i"

          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  :data-testid="'checkbox-input'+i"
                  :input-value="active"
                  color="secondary"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.nice_name"></v-list-item-title>
                <StreamfieldBlock
                  v-for="(block, blockIndex) in item.additional_detail"
                  v-show="checkboxGroupValue.includes(item.layer_name)"
                  :id="i"
                  :key="blockIndex"
                  :block="block"
                ></StreamfieldBlock>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import StreamfieldBlock from "../blocks/streamfieldBlock.vue";

export default {
  name: "RadioGroupBlock",
  props: ["groupData"],
  components: {
    StreamfieldBlock,
  },
  data: () => ({
    groupValue: 0,
    checkboxGroupValue: [],
    checked: false,
    selectedItem: 0,
    showStream: false,
    selectedLayers: {}
  }),
  mounted() {
    console.log(this.groupData, "grouped data");
  },
  beforeCreate: function () {
    this.$options.components.StreamfieldBlock =
      require("../blocks/streamfieldBlock.vue").default;
  },
  methods: {
    updateStore: function (visibleLayerIndex) {
      let layerOptionsData = {};

      // Iterate through the options on this group and set layer visibility
      this.groupData.layers.forEach(function (item, i) {
        let visible = i === visibleLayerIndex;
        let layers = item.layer_name.split(",");

        layers.forEach(function (layer) {
          if (visible) layerOptionsData[layer] = true;
          //Make layers that belong to the active radio button visible
          else if (!(layer in layerOptionsData))
            layerOptionsData[layer] = false; //Only set the layer to be invisible if its not already being set visible
        });
      });

      let payload = { key: this._uid, data: layerOptionsData };
      console.log("payloadRadio", payload);

      this.$store.commit("wagtail/setLayerOptions", payload);
    },

    updateStoreCheckbox: function (visibleLayerIndex) {
      // Object.keys(this.selectedLayers).forEach(item => {
      //     this.selectedLayers[item] = visibleLayerIndex.forEach(item => item.split('|').some(_layer =>  _layer === item))
      // })
      Object.keys(this.selectedLayers).forEach(item => {
          this.selectedLayers[item] = false
      })

      visibleLayerIndex.forEach(layer => {

        const layers = layer.split(',')

        layers.forEach(item => {
          this.selectedLayers[item] = true
        })
      })

      // let payload = { key: this._uid, data: {
      //   '3m shared user path': true,
      //   '4m wide shared user path': true,
      //   'Indicative culvert works': true,
      //   'Indicative culvert works LEGEND': true,
      //   'Line marking': true,
      //   'New bridge': true,
      //   'Pavement': true,
      //   'Pavement LEGEND': true,
      // } };
      let payload = { key: this._uid, data: this.selectedLayers };

      console.log("payloadCheckbox", payload, visibleLayerIndex, this.selectedLayers);
      this.$store.commit("wagtail/setLayerOptions", payload);
    },
  },
  created() {
    let self = this;

    this.groupData.layers.forEach(function (item, i) {
      if (item.active === true) self.groupValue = i;
    });

    this.groupData.layers.forEach(function (item) {
      if (item.active === true) self.checkboxGroupValue.push(item.layer_name);
    });

    this.selectedLayers =  this.groupData.layers.reduce(function (prev, item) {
      let layers = item.layer_name.split(",");
      layers.forEach(function (layer) {
        prev[layer] = false; //Add the layer along with its appropriate visibility
      });
      return prev
    }, {});

    this.showStream = true;
    //Ensure the first filter is applied at load
    this.updateStore(this.groupValue);
    this.updateStoreCheckbox(this.checkboxGroupValue);
  },
  computed: {
    layerOptions() {
      return this.$store.state.wagtail.layerOptions;
    },
    // tickLabels: function() {
    //   let labels = []
    //   this.sliderData.forEach( function(item) {
    //     labels.push(item.step)
    //   })
    //   return labels
    // }
    //Edit Here
    // isStreamfieldAvailable(){
    //  this.groupData.layers.forEach(function (item, i) {
    //   if (item.additional_detail == undefined || null) return true;
    //   return false
    // });
    // }
  },
  watch: {
    layerOptions: {
      handler(newVal, oldVal) {
        // React to changes in layerOptions here
        console.log("L OPTIONS changed:", newVal);
        if (this.groupData.group_item_type === "radioBtn") {
          // Update groupValue for radio buttons
          for (let i = 0; i < this.groupData.layers.length; i++) {
            let layerName = this.groupData.layers[i].layer_name;
            if (newVal[this._uid] && newVal[this._uid][layerName]) {
              if (this.groupValue !== i) {
                this.groupValue = i;
                break;
              }
            }
          }
        } else if (this.groupData.group_item_type === "checkBox") {
          // Update checkboxGroupValue for checkboxes
          let newCheckboxValues = [];
          this.groupData.layers.forEach((layer) => {
            if (newVal[this._uid] && newVal[this._uid][layer.layer_name]) {
              newCheckboxValues.push(layer.layer_name);
            }
          });
          if (
            JSON.stringify(this.checkboxGroupValue) !==
            JSON.stringify(newCheckboxValues)
          ) {
            // this.checkboxGroupValue = newCheckboxValues;
          }
        }
      },
      deep: true,
    },
    groupValue: function (newValue) {
      this.updateStore(newValue);
    },
    data: function (newVal) {
      console.log(newVal, "datastore");
    },
    // checkboxGroupValue: function (newValue) {
    //   this.updateStoreCheckbox(newValue);
    // },
  },
  beforeDestroy() {
    let payload = { key: this._uid, data: null };
    this.$store.commit("wagtail/setLayerOptions", payload);
  },
};
</script>

<style scoped>
.radio-bg {
  background-color: rgb(229, 229, 229);
}
</style>