<template>
  <div class="full-screen">
    <v-fade-transition>
      <LoadingOverlay
        class="loading-overlay"
        :title="data.title"
        v-show="!loaded"
      />
    </v-fade-transition>

    <LightboxContentLayout
      :accessibilityText="data.accessibility_text"
      :ariaDescription="undefined"
      :captionText="data.caption"
      :accessibilityTextFlag="false"
      :vimeoVideo="data.vimeo_video"
    >
      <iframe
        v-show="data.vimeo_video == true"
        frameborder="0"
        class="vimeo-style"
        ref="vimeo"
        :src="formattedVimeoUrl"
      ></iframe>

      <iframe
        v-show="!data.vimeo_video"
        ref="iframe"
        :src="data.url"
        width="100%"
        height="100%"
        seamless="true"
        frameBorder="0"
      ></iframe>
    </LightboxContentLayout>
  </div>
</template>

<script>
import LoadingOverlay from "./LoadingOverlay.vue";
import LightboxContentLayout from "./LightboxContentLayout.vue";

export default {
  name: "LightboxIframeCard",
  data: () => ({
    dialog: true,
    loaded: false,
    formattedVimeoUrl: null,
    videoUrl: null
  }),
  mounted() {
    let self = this;
    this.videoUrl = this.data.url;
    this.$refs.iframe.addEventListener("load", function () {
      self.loaded = true;
    });

    if (this.data.vimeo_video !== null && this.data.vimeo_video !== undefined) {
      if (
        !document.querySelector(
          'script[src="https://player.vimeo.com/api/player.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.src = "https://player.vimeo.com/api/player.js";
        document.body.appendChild(script);
      }
      let self = this;

      this.$refs.vimeo.addEventListener("load", function () {
        self.loaded = true;
      });
    }
  },
  watch: {
    // Watcher to detect changes in the videoUrl
    videoUrl: {
      immediate: true, // Run the watcher immediately when the component is created
      handler(newUrl) {
        if (newUrl) {
          this.fetchVimeoOEmbed(newUrl);
        } else {
          this.formattedVimeoUrl = null; // Reset if the URL is empty
        }
      },
    },
  },
  methods: {
    async fetchVimeoOEmbed(videoUrl) {
      try {
        // Construct the oEmbed API URL with the video URL as a parameter
        const apiUrl = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
          videoUrl
        )}`;

        // Make a GET request to the oEmbed API
        const response = await fetch(apiUrl);

        // Check if the response is OK (status code 200)
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        // Parse the response as JSON
        const data = await response.json();

        // Get the 'uri' field from the response and process it
        const videoUri = data.uri; // Example: "/videos/12345678" or "/videos/12345678:somekey"
        this.formattedVimeoUrl = this.reformatVimeoUri(videoUri);
      } catch (error) {
        console.error("Failed to fetch oEmbed data:", error);
        this.formattedVimeoUrl = null;
      }
    },

    // Method to reformat the Vimeo 'uri' field into the desired URL
    reformatVimeoUri(videoUri) {
      // Extract the video ID and optional key
      const regex = /\/videos\/(\d+)(?:[:\/](\w+))?/;
      const match = videoUri.match(regex);

      if (!match) {
        console.error("Invalid Vimeo URI format:", videoUri);
        return null;
      }

      const videoId = match[1];
      const videoKey = match[2];

      // Construct the new player URL
      let playerUrl = `https://player.vimeo.com/video/${videoId}`;
      if (videoKey) {
        playerUrl += `?h=${videoKey}`;
      }

      return playerUrl;
    },
  },
  unmounted() {
    // Remove the Vimeo API script when component is unmounted
    if (this.vimeoScript && document.body.contains(this.vimeoScript)) {
      document.body.removeChild(this.vimeoScript);
    }
  },
  props: ["data"],
  computed: {
    image() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    caption() {
      if (this.data.caption) return this.data.caption;
      return "";
    },
  },
  components: { LoadingOverlay, LightboxContentLayout },
};
</script>

<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

.loading-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
}

.vimeo-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}
</style>
