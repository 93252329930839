import AuthService from '../services/auth.service';

const initialState = {
  status: { loggedIn: AuthService.isLoggedIn() },
}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    loginMicrosoft({ commit }, code) {
      if(!code) {
        return AuthService.loginMicrosoft();
      } else {
      return AuthService.loginMicrosoft(code).then(
        response => {
          if(response.status && response.status === 401) {
            commit('loginFailure');
            return Promise.reject("Not Authorized");
          } else {
            commit('loginSuccess');
            return Promise.resolve(response.data);
          }
        }
      );
      }

    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    refresh({ commit }) {
      return AuthService.refresh().then(
        response => {
          commit('loginSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state) {
      state.status.loggedIn = true;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
    },
    logout(state) {
      state.status.loggedIn = false;
      // router.push("/login");
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
