<template>
  <v-dialog
    persistent
    max-width="640px"
    height="100vh"
    v-model="showLanding"    
    content-class="landing_streamfield_dialog"
    
  >
    <v-card class="welcome_lightbox" style="overflow: hidden" data-testid="welcome-dialog">
      <FocusLoop :is-visible="showLanding" :auto-focus="true">
        <v-card-text class="pt-5 pb-0">
          <v-container class="pt-0 pb-0">
            <v-row v-for="(block, i) in data.landing_streamfield" :key="i" :class="`lsr-${i}-${data.id}`">
              <v-col>
                <StreamfieldBlock :block="block" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container class="pt-0 pb-0">
            <v-row justify="center">
              <v-col cols="6" 
                data-testid="ls-button-container"
                :sm="data.landing_streamfield_button_settings.button_width_mobile" 
                :md="data.landing_streamfield_button_settings.button_width_tablet" 
                :lg="data.landing_streamfield_button_settings.button_width_desktop" 
                :xl="4">
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    flat
                    @click="welcomeClosed()"
                    :class="`elevation-${hover ? 6 : 0}`"
                    class="mx-auto transition-swing"
                    tabindex="-1"
                  >
                    <v-card-text class="pb-0 text-center">
                      <v-btn
                        id="explore-btn"
                        data-testid="explore-btn"
                        aria-hidden="false"
                        :aria-label="buttonText"
                        class="streamfield-btn"
                        tabindex="0"
                        fab
                        x-large
                        color="secondary"
                      >
                        <v-icon x-large>{{ data.menu_icon }}</v-icon>
                      </v-btn>
                    </v-card-text>
                    <v-card-text class="text-center subtitle-1">
                      {{ buttonText }}
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col
                v-for="(block, i) in data.landing_shortcuts"
                :key="i"
                cols="6"
                :data-testid="`ls-shortcut-button-container${i}`"
                :sm="block.value.button_width_mobile"
                :md="block.value.button_width_tablet"
                :lg="block.value.button_width_desktop"
                :xl="4"
              >
                <PageShortcutBlock
                  :icon="block.value.page.menu_icon"
                  :title="block.value.caption"
                  @click="shortcutClicked(block.value.page.detail_url), welcomeClosed();"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </FocusLoop>
    </v-card>
  </v-dialog>
</template>

<script>
import StreamfieldBlock from "../../components/blocks/streamfieldBlock.vue";
import PageShortcutBlock from "../../components/blocks/pageShortcutBlock.vue";
import { FocusLoop } from "@vue-a11y/focus-loop";
import * as arrowKeyNavigation from "arrow-key-navigation";

//enables next & previous tab style DOM navigation using arrow keys
arrowKeyNavigation.register();

export default {
  name: "LandingStreamfield",
  props: ["pageType", "data"],
  data: () => ({ showLanding: true, pageViewed: false, displayLanding: true }),
  components: { StreamfieldBlock, PageShortcutBlock, FocusLoop },
  computed: {
    buttonText() {
      if (this.data.landing_streamfield_button_settings.button_text) {
        return this.data.landing_streamfield_button_settings.button_text
      } else {
        return `Explore the ${this.pageType}`
      }
    },
    landingActive() {
      if (localStorage.getItem("accessibilityModeState") == "true") {
        return false;
      } else return this.$store.state.wagtail.setLandingStreamfield;
    },
    isPageViewed() {
      return this.$store.state.wagtail.pageViewed;
    },
  },
  mounted() {
    const element_btn = document.getElementById("explore-btn");
    element_btn.focus();
    element_btn.addEventListener("click", () => {
      this.welcomeClosed();
    });

    console.log(
      this.data,
      this.$store.state.wagtail.setLandingStreamfield,
      "data from landing"
    );
    let html = document.querySelector("html");
    html.style.overflow = "hidden";

    console.log(
      this.landing_shortcuts,
      this.landing_streamfield,
      "landingstream"
    );
    console.log(this.data, "test data");
  },

  methods: {
    shortcutClicked(url) {
      //When we click a shortcut, we either route to that page, or if the shortcut is the current page we close the dialog
      let pagePath = new URL(url).pathname;
      let currPath = this.currentPath;
      this.showLanding = false;

      if (currPath === pagePath) this.welcomeClosed();
      else this.$router.push(pagePath);
    },
    welcomeClosed() {
      console.log("test");
      // this.showLanding = false;
      // console.log("destory landing")
      // sessionStorage.setItem("showLanding", false);
      this.showLanding = false;
      // this.$store.commit(
      //   "wagtail/setLandingStreamfield",
      //   this.showLanding,
      //   { root: true }
      // );
      if (this.pageType == "room") {
        this.$root.$refs.engagementRoom.displayInstructions();
        let html = document.querySelector("html");
        html.style.overflow = "hidden";
      } else {
        let html = document.querySelector("html");
        // html.style.overflowY = 'scroll'
        html.style.overflowX = "hidden";
      }
      //   this.$nextTick(() => {
      //     if (this.accessibilityMode == false) {
      //       const elements = document.querySelectorAll("#hotspot-img");
      //       elements[0].focus();
      //     }
      //   });

      // console.log("Welcome Closed")
      // this.$store.commit('wagtail/setViewedPage', [
      //   ...this.$store.state.wagtail.pageViewed,
      //   this.data.id
      // ])

      this.$emit("closed");
    },
  },
  watch: {
    isPageViewed(newVal) {
      console.log("showlanding", newVal);

      let html = document.querySelector("html");

      if (this.pageType !== "room") {
        console.log("newVal is false");
        html.style.overflowY = "scroll";
        html.style.overflowX = "hidden";
      } else {
        html.style.overflowY = "hidden";
        html.style.overflowX = "hidden";
      }
    },
    landingActive(newVal) {
      console.log(newVal, "from landing active");
    },
  },
};
</script>

<style scoped>
.lightbox-btn:focus-visible {
  outline: 4px dashed darkorange;
}

.streamfield-btn:focus-visible {
  outline: 4px dashed darkorange;
}

::v-deep .v-dialog__content {
  /* align-self: flex-start; */
  height: 100vh !important;
}

::v-deep #inspire > div.v-dialog__content.v-dialog__content--active {
  height: 100vh !important;
}

::v-deep #inspire > div.v-overlay {
  max-height: 100vh !important;
}

.v-dialog__content--active {
  z-index: 9999 !important; /* Ensure this value is higher than any other element */
}

.v-overlay__scrim {
  z-index: 9998 !important; /* Make sure the scrim (background overlay) is just below the dialog */
}
</style>
