import { render, staticRenderFns } from "./imageBlock.vue?vue&type=template&id=edcc6aba&scoped=true"
import script from "./imageBlock.vue?vue&type=script&lang=js"
export * from "./imageBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edcc6aba",
  null
  
)

export default component.exports